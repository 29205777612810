import { DocumentNode } from 'apollo-boost';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { getActiveLanguage } from '../lib/getActiveLanguage';

export const useQueryWithLanguage = (
  QUERY: DocumentNode,
  options?: QueryHookOptions<any, any>
) => {
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: { language: getActiveLanguage(), ...options?.variables },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
