import React, { ReactNode } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getActiveLanguage } from './lib/getActiveLanguage';

interface Props {
  children: ReactNode;
}

const LANGUAGE_QUERY = gql`
  query getLanguageQuery {
    polylangStrings {
      en
      keys
      sq
    }
  }
`;

const LanguageProvider = (props: Props) => {
  const { loading, data } = useQuery(LANGUAGE_QUERY);
  // console.log('language data', data);

  const stringsEn = data?.polylangStrings?.en || [];
  const stringsSq = data?.polylangStrings?.sq || [];
  const stringsKeys = data?.polylangStrings?.keys || [];
  // console.log(stringsEn);
  const stringsEnObject = {} as any;
  const stringsSqObject = {} as any;

  stringsKeys.forEach((s: string, index: number) => {
    stringsEnObject[s] = stringsEn[index];
  });

  stringsKeys.forEach((s: string, index: number) => {
    stringsSqObject[s] = stringsSq[index];
  });

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translations: stringsEnObject,
      },
      sq: {
        translations: stringsSqObject,
      },
    },
    fallbackLng: getActiveLanguage().toLowerCase(),
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  return <>{loading ? <></> : <>{props.children}</>}</>;
};

export default LanguageProvider;
