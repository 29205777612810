import React from 'react';
import PartnersIcon from '../../images/partner-icon.png';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import { Container } from 'reactstrap';
import { gql } from 'apollo-boost';
import ServicesItem from '../SharedComponents/ServicesItem/ServicesItem';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './Services.scss';

const GET_SERVICES_CONTENT = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "services", language: $language }) {
      nodes {
        content
        ${makeLanguageFieldKey('Services')} {
          pageBannerTitle
          serviceItem {
            content
            title
            icon {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export const Services = () => {
  const { loading, error, data } = useQueryWithLanguage(GET_SERVICES_CONTENT);

  const renderHTML = (rawHTML: string) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Something went wrong</p>;
  }
  const ServicesData = data?.pages?.nodes[0] || null;
  const servicesKey = makeLanguageFieldKey('Services');
  return (
    <div className="BlueContainer">
      <div className="Services fade-in-up">
        <PageBanner
          title={ServicesData[servicesKey]?.pageBannerTitle}
          icon={PartnersIcon}
        >
          <div className="Partners--content">
            <p>{renderHTML(ServicesData[servicesKey].content)}</p>
          </div>
        </PageBanner>
        <Container>
          {ServicesData[servicesKey]?.serviceItem.map((post: any) => (
            <ServicesItem
              title={post.title}
              content={post.content}
              icon={post.icon.mediaItemUrl}
            />
          ))}
        </Container>
      </div>
    </div>
  );
};
