import React from 'react';
import { Container } from 'reactstrap';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import {
  ProductItem,
  Product,
} from '../../SharedComponents/ProductItem/ProductItem';

import './SimilarProductPost.scss';

const GET_SIMILAR_PRODUCT_POSTS = gql`
  query getCategorySimilarPostsBySlug($slug: [String]) {
    productCategories(where: { slug: $slug }) {
      nodes {
        products(last: 4) {
          nodes {
            title
            id
            link
            slug
            content
            featuredImage {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

interface Props {
  slug: string;
}

export function SimilarProductPost(props: Props) {
  const { loading, error, data } = useQuery(GET_SIMILAR_PRODUCT_POSTS, {
    variables: {
      slug: props.slug,
    },
  });

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const posts = data?.productCategories?.nodes[0]?.products?.nodes;

  return (
    <div className="SimilarProductPost">
      <Container>
        <h1>Shiko Produktet tjera</h1>
        <div className="myrow">
          {!posts || posts.length === 0 ? (
            <div>There are no products ...</div>
          ) : (
            posts.map((product: Product) => (
              <ProductItem key={product.id} product={product} />
            ))
          )}
        </div>
      </Container>
    </div>
  );
}
export default SimilarProductPost;
