import React from 'react';
import ContentLoader from 'react-content-loader';

const ProductCategoriesLoader = () => (
  <ContentLoader
    height={1200}
    width={1500}
    speed={1}
    primaryColor="#01293e"
    secondaryColor="#57ffff"
  >
    <rect x="0" y="100" rx="0" ry="0" width="405" height="45" />
    <rect x="0" y="240" rx="0" ry="0" width="280" height="380" />
    <rect x="310" y="240" rx="0" ry="0" width="280" height="380" />
    <rect x="620" y="240" rx="0" ry="0" width="280" height="380" />
  </ContentLoader>
);

export default ProductCategoriesLoader;
