import { gql } from 'apollo-boost';
import { useQueryWithLanguage } from '../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../lib/getActiveLanguage';

const aboutPageKey = makeLanguageFieldKey('aboutPage');

const GET_CLIENT_POSTS = gql`
  query ClientQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "about", language: $language }) {
      nodes {
        ${makeLanguageFieldKey('aboutPage')} {
          clientsAndReferences {
            clientDsc
            clientTitle
            items {
              icon {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const useClientsQuery = () => {
  const { loading, error, data, refetch } = useQueryWithLanguage(
    GET_CLIENT_POSTS
  );

  const clientsData =
    data?.pages?.nodes[0]?.[aboutPageKey]?.clientsAndReferences || [];

  return {
    loading,
    error,
    data: clientsData,
    refetch,
  };
};
