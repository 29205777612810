import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import './ProductItem.scss';
import { string } from 'prop-types';

export type Product = {
  id: string;
  title: string;
  slug?: string;
  link: string;
  content: string;
  featuredImage?: {
    mediaItemUrl: string;
  };
  productCategories?: {
    nodes: {
      slug: string;
    }[];
  };
};

interface Props {
  product: Product;
}

export function ProductItem(props: Props) {
  const router = useRouteMatch<{ productCategorySlug: string }>();

  const productCategorySlug = router.params.productCategorySlug;

  const prodFinalSlug =
    props.product?.productCategories?.nodes[0]?.slug || productCategorySlug;

  return (
    <div className="ProductItem">
      <Link to={`/products/${prodFinalSlug}/${props.product.slug}`}>
        <div className="ProductItem--thumb">
          <img src={props.product.featuredImage?.mediaItemUrl} alt="" />
        </div>
        <div className="ProductItem--dsc">
          <h3>{props.product.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.product.content }}></p>
        </div>
      </Link>
    </div>
  );
}
export default ProductItem;
