import React from 'react';
import { Navbar, Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import HeaderNavigation from './HeaderNavigation/HeaderNavigation';
import { useTranslation } from 'react-i18next';

import './Header.scss';

export const Header = (props: any) => {
  const { i18n } = useTranslation();

  let className =
    props.location === '/home' || props.location === '/'
      ? 'header-active'
      : 'Header';

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const setActiveLanguage = (lang: string) => {
    localStorage.setItem('INET_ACTIVE_LANGUAGE', lang);
    changeLanguage(lang);
  };

  return (
    <div className={className}>
      <Navbar expand="md">
        <Container>
          <NavLink to="" className="navbar-brand">
            <img src={require('../../images/logo.svg')} alt="" />
          </NavLink>
          <HeaderNavigation />
        </Container>
      </Navbar>
      <div className="langSwitch">
        <ul>
          <li>
            <span
              className="nav-link"
              role="button"
              data-active={
                localStorage.getItem('INET_ACTIVE_LANGUAGE') === 'sq'
              }
              onClick={() => setActiveLanguage('sq')}
            >
              SQ
            </span>
          </li>
          <li>
            <span
              role="button"
              data-active={
                localStorage.getItem('INET_ACTIVE_LANGUAGE') === 'en'
              }
              onClick={() => setActiveLanguage('en')}
            >
              EN
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
