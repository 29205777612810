import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import './ProductSingleAccordion.scss';

type AcordionPost = {
  accordionContent: string;
  accordionTitle: string;
};

interface ProductSingleAccordionProps {
  title: string;
  accordionImg?: string;
  accordionPosts?: AcordionPost[];
}

export function ProductSingleAccordion(props: ProductSingleAccordionProps) {
  const [activePost, setActivePost] = useState<string | null>(null);

  const toggle = (id: string | null) => {
    if (activePost === id) {
      setActivePost(null);
    } else {
      setActivePost(id);
    }
  };

  return (
    <div className="ProductSingleAccordion">
      <div className="ProductSingleAccordion-header">
        <h3>{props.title}</h3>
        {props.accordionImg && (
          <img src={props.accordionImg || ''} alt={props.title} />
        )}
      </div>
      <div className="ProductSingleAccordion-posts">
        {!props.accordionPosts || props.accordionPosts.length === 0 ? (
          <div>There are no posts !</div>
        ) : (
          props.accordionPosts.map((post, i) => {
            const id = `${post.accordionTitle}-${i}`;
            return (
              <div
                className="ProductSingleAccordion-posts-post"
                data-opened={activePost === id}
                key={id}
              >
                <div
                  className="ProductSingleAccordion-posts-post-title"
                  onClick={() => toggle(id)}
                >
                  <span>{post.accordionTitle}</span>
                </div>
                <Collapse
                  className="ProductSingleAccordion-posts-post-content-holder"
                  isOpen={activePost === id}
                >
                  <div
                    className="ProductSingleAccordion-posts-post-content"
                    dangerouslySetInnerHTML={{
                      __html: post.accordionContent || '',
                    }}
                  />
                </Collapse>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
export default ProductSingleAccordion;
