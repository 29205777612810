import React from 'react';
import { Container } from 'reactstrap';
import PartnersIcon from '../../images/partner-icon.png';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import { gql } from 'apollo-boost';
import { usePartnersQuery } from '../../hooks/usePartnersQuery';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';
import './Partners.scss';

const partnersKey = makeLanguageFieldKey('partnerItems');
const GET_PARTNERS_CONTENT = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "partners", language: $language }) {
      nodes {
        id
        content
        ${partnersKey}{
          pageBannerTitle
        }
      }
    }
  }
`;
export default function Partners() {
  const partnersQuery = usePartnersQuery();

  const {
    data: dataContent,
    // error: errorContent,
    // loading: loadingContent,
  } = useQueryWithLanguage(GET_PARTNERS_CONTENT);
  const renderHTML = (rawHTML: string) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  // if (loadingItem || loadingContent) {
  //   return <p>Loading...</p>;
  // }
  // if (errorItem || errorContent) {
  //   return <p>Something went wrong</p>;
  // }

  const partnersContent = dataContent?.pages?.nodes[0].content || null;
  const partnersTitle =
    dataContent?.pages?.nodes[0][partnersKey]?.pageBannerTitle || null;

  return (
    <div className="Partners ">
      <PageBanner title={partnersTitle} icon={PartnersIcon}>
        <div className="Partners--content fade-in-up">
          <span></span>
          <p>{renderHTML(partnersContent)}</p>
        </div>
      </PageBanner>
      <Container>
        <div className="myRow">
          <div className="Partners--holder">
            {!partnersQuery.data || partnersQuery.data.length === 0 ? (
              <div>There are no partners data!</div>
            ) : (
              partnersQuery.data.map((post, i) => {
                const imageUrl = post?.image?.mediaItemUrl;
                return (
                  <div
                    key={`${imageUrl}-${i}`}
                    className="Partners--items column-4 column-mob-12"
                  >
                    <div className="Partners--items--img">
                      {imageUrl && <img src={imageUrl} alt="" />}
                    </div>
                    {post.content && <p>{post.content}</p>}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
