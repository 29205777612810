import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { getActiveLanguage } from '../lib/getActiveLanguage';

export const mainMenuKey =
  getActiveLanguage() === 'SQ' ? 'MENU_1___SQ' : 'MENU_1';

const GET_MENU_QUERY = gql`
  query MyQuery {
    menus(where: { location: ${mainMenuKey} }) {
      nodes {
        id
        menuItems {
          nodes {
            label
            id
            url
          }
        }
      }
    }
  }
`;

interface MenuItem {
  id: string;
  label: string;
  url: string;
}

export const useNavigationQuery = () => {
  const { loading, error, data, refetch } = useQuery(GET_MENU_QUERY);
  const menuData: MenuItem[] = data?.menus?.nodes[0]?.menuItems?.nodes || null;

  return {
    loading,
    error,
    data: menuData,
    refetch,
  };
};
