import React from 'react';
import { gql } from 'apollo-boost';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaqPost } from '../Faq/Faq';
import { useTranslation } from 'react-i18next';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';
import './HomeFaqs.scss';
const faqKey = makeLanguageFieldKey('FaqArticles');
const GET_FAQ_CONTENT = gql`
  query FaqQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "faq", language: $language }, last: 3) {
      nodes {
        id
        ${faqKey} {
          items {
            content
            fieldGroupName
            title
          }
        }
      }
    }
  }
`;

export default function HomeFaqs() {
  const { loading, error, data } = useQueryWithLanguage(GET_FAQ_CONTENT);
  const faqContent = data?.pages?.nodes[0]?.[faqKey]?.items || [];
  const { t } = useTranslation();
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Something went wrong</p>;
  }
  return (
    <div className="HomeFaqs">
      <Container>
        <h1 className="text-center">{t('Frequently Asked Questions')}</h1>
        <div className="faqRow">
          {!faqContent || faqContent.length === 0 ? (
            <div>There are no Items ...</div>
          ) : (
            faqContent.map((faq: FaqPost, i: number) => (
              <div key={`${faq.title}-${i}`} className="faqItem">
                <h3>{faq.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: faq.content.substr(0, 200),
                  }}
                ></p>
              </div>
            ))
          )}
        </div>
        <div className="link-holder text-center">
          <Link to={`/faq`}>
            <span></span>
            {t('Visit FAQ')}
          </Link>
        </div>
      </Container>
    </div>
  );
}
