import React from 'react';
import BlueContainer from '../BlueContainer/BlueContainer';
import BlogItem from '../BlogItem/BlogItem';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetBlogsQuery } from '../../../hooks/useGetBlogsQuery';
import { Container } from 'reactstrap';

interface Props {
  title: string;
  limit?: number;
}

export default function SimilarBlogPost(props: Props) {
  const blogsData = useGetBlogsQuery();
  const { t } = useTranslation();

  return (
    <BlueContainer>
      <Container>
        <div className="BlockSectionItem">
          <h1
            className="BlockSectionItem-title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          <span className="lineBg"></span>
          <div className="my-row">
            {blogsData.loading ? (
              <span>Loading Blogs ...</span>
            ) : (
              blogsData.data.map((post: any, index: number) => {
                if (props.limit && index >= props.limit) {
                  return null;
                }
                return (
                  <div
                    key={post.title}
                    className="Blog--items column-4 column-mob-12"
                  >
                    <BlogItem
                      link={post.slug}
                      title={post.title}
                      date={post.date}
                      image={post.featuredImage.mediaItemUrl}
                      description={post.excerpt}
                    />
                  </div>
                );
              })
            )}
          </div>
          <div className="link-holder text-center">
            <Link to={`/blog`}>
              <span></span> {t('Visit blog')}
            </Link>
          </div>
        </div>
      </Container>
    </BlueContainer>
  );
}
