import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Header } from '../Header/Header';
import { Services } from '../Services/Services';
import { Faq } from '../Faq/Faq';
import Partners from '../Partners/Partners';
import Blog from '../Blog/Blog';
import Home from '../Home/Home';
import { useHistory } from 'react-router';
import { BlogSingle } from '../BlogSingle/BlogSingle';
import About from '../About/About';
import ProductCategories from '../Products/ProductCategories';
import { ProductCategoriesSingle } from '../Products/ProductCategoriesSingle';
import { ProductSingle } from '../Products/ProductSingle';
import Contact from '../Contact/Contact';
import TermsContition from '../TermsCondition/TermsContition';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Footer from '../Footer/Footer';

import './App.scss';

const App: React.FC = () => {
  const location = useHistory();
  return (
    <div className="App">
      <Header location={location.location.pathname} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />

        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog/:id" component={BlogSingle} />

        <Route exact path="/services" component={Services} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/about" component={About} />
        <Route exact path="/products" component={ProductCategories} />
        <Route
          exact
          path="/products/:productCategorySlug"
          component={ProductCategoriesSingle}
        />
        <Route
          exact
          path="/products/:productCategorySlug/:productSlug"
          component={ProductSingle}
        />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/terms-conditions" component={TermsContition} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
