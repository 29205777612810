import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { ProductCategoryPosts } from './ProductCategoryPosts';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './ProductCategoriesSingle.scss';
import { Loader } from '../SharedComponents/Loader/Loader';

const productCategorySingleKey = makeLanguageFieldKey(
  'ProductCategoriesFields'
);

const GET_PRODUCT_CATEGORY = gql`
  query getProductCategory($slug: [String], $language: LanguageCodeFilterEnum) {
    productCategories(where: { slug: $slug, language: $language }) {
      nodes {
        id
        name
        products {
          nodes {
            id
            title
            slug
            link
            content
            featuredImage {
              mediaItemUrl
            }
          }
        }
        ${productCategorySingleKey} {
          productCategories {
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export function ProductCategoriesSingle(
  props: RouteComponentProps<{ productCategorySlug: string }>
) {
  const { productCategorySlug } = props.match.params;

  const { loading, error, data } = useQueryWithLanguage(GET_PRODUCT_CATEGORY, {
    variables: {
      slug: productCategorySlug,
    },
  });

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center mt-5"
        style={{ height: '100%' }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>Error...</p>;
  }

  const productCategory = data?.productCategories?.nodes[0] || [];

  const backgroundImg =
    productCategory?.[productCategorySingleKey]?.productCategories?.image
      ?.mediaItemUrl || '';

  return (
    <div className="ProductCategoriesSingle">
      <div
        className="ProductCategoriesSingle--banner"
        style={{
          background: `url(${backgroundImg}) no-repeat center`,
        }}
      >
        <div className="ProductCategoriesSingle-dsc">
          <h1 className="text-center">{productCategory.name || ''}</h1>
        </div>
      </div>
      <ProductCategoryPosts products={productCategory?.products?.nodes} />
    </div>
  );
}
