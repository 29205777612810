import React from 'react';
import { gql } from 'apollo-boost';
import { Container } from 'reactstrap';
import moment from 'moment';
import './BlogSingle.scss';
import { ContainerSmall } from '../SharedComponents/ContainerSmall/ContainerSmall';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

export const GET_POST = gql`
  query getSinglePostQuery($language: LanguageCodeFilterEnum, $slug: String) {
    posts(where: { name: $slug, language: $language }) {
      edges {
        node {
          id
          slug
          title
          date
          excerpt
          content
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export const BlogSingle = (props: any) => {
  const { loading, error, data } = useQueryWithLanguage(GET_POST, {
    variables: {
      slug: `${props.match.params.id}`,
    },
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error...</p>;
  }

  const postData = data?.posts?.edges[0]?.node;

  return (
    <div className="BlogSingle">
      <div
        className="BlogSingle--banner"
        style={{
          background: `url(${postData.featuredImage.mediaItemUrl}) no-repeat center`,
        }}
      >
        <div className="ContainerSmall">
          <div className="BlogSingle--banner--title">
            <h1>{postData.title}</h1>
          </div>
        </div>
      </div>

      <div className="BlogSingle--content">
        <div className="ContainerSmall">
          <span className="date">
            {moment(postData.date).format('MMM DD, YYYY')}
          </span>
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: postData.excerpt }}
          ></div>
          <p dangerouslySetInnerHTML={{ __html: postData.content }}></p>
        </div>
      </div>
      <Container>
        <span className="lineBg"></span>
      </Container>
      <ContainerSmall>
        <div className="shareLinks">
          <p>Share this article:</p>
          <FacebookShareButton
            className="facebook-icon"
            url={window.location.href}
          >
            <span></span>
          </FacebookShareButton>
          <TwitterShareButton
            className="twitter-icon"
            url={window.location.href}
          >
            <span></span>
          </TwitterShareButton>
          <LinkedinShareButton
            className="linkedin-icon"
            url={window.location.href}
          >
            <span></span>
          </LinkedinShareButton>
        </div>
      </ContainerSmall>
    </div>
  );
};
