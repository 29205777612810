import React from 'react';
import { ReactComponent as InetLoader } from '../../../images/inet_loader.svg';

export const Loader = () => {
  return (
    <span className="Loader">
      <InetLoader />
    </span>
  );
};
