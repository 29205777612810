import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

import './ProductSlider.scss';

type SliderImage = {
  image: {
    mediaItemUrl: string;
  };
};

interface Props {
  sliderImages: SliderImage[];
}

export const ProductSlider = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const { sliderImages } = props;

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === sliderImages.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? sliderImages.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <div className="ProductSlider">
      {!sliderImages ? (
        <div>No product images found !</div>
      ) : (
        <>
          <div className="ProductSlider-thumbnails">
            {sliderImages.map((item, index) => (
              <div
                key={item.image.mediaItemUrl}
                className="thumb"
                data-active={activeIndex === index}
                onClick={() => goToIndex(index)}
              >
                <img src={item?.image.mediaItemUrl} alt="" />
              </div>
            ))}
          </div>
          <div className="ProductSlider-active-thumb">
            <Carousel
              keyboard={false}
              pause={false}
              ride="carousel"
              interval={false}
              slide={false}
              className="carousel-fade"
              activeIndex={activeIndex}
              next={next}
              previous={previous}
            >
              {sliderImages.map(item => {
                return (
                  <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={item?.image.mediaItemUrl}
                  >
                    <img
                      src={item?.image.mediaItemUrl}
                      alt={item?.image.mediaItemUrl}
                    />
                  </CarouselItem>
                );
              })}

              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />

              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </div>
        </>
      )}
    </div>
  );
};
