import React from 'react';
import { Container } from 'reactstrap';
import ProductItem, {
  Product,
} from '../SharedComponents/ProductItem/ProductItem';

interface Props {
  products?: Product[];
}

export const ProductCategoryPosts = (props: Props) => {
  return (
    <div className="ProductCategoryPosts">
      <div className="ProductItemWrapper">
        <Container>
          <div className="ProductItemRow">
            {!props.products || props.products.length === 0 ? (
              <div>There are no products ...</div>
            ) : (
              props.products.map(product => (
                <ProductItem key={product.id} product={product} />
              ))
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
