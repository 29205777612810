import React from 'react';
import { gql } from 'apollo-boost';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HomeProductSlider } from '../../SharedComponents/HomeProductSlider/HomeProductSlider';
import { useQueryWithLanguage } from '../../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../../lib/getActiveLanguage';

import './HomeProductsSection.scss';
import HomeFaqs from '../../HomeFaqs/HomeFaqs';
const homeProdKey = makeLanguageFieldKey('Home');
const GET_PRODUCTS_HOME = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "home", language: $language }) {
      nodes {
        ${homeProdKey} {
          productSection {
            productSectionDescription
            productSectionLink
            productSectionTitle
            productsHome {
              ... on Products {
                id
                slug
                title
                content
                productCategories {
                  nodes {
                    slug
                  }
                }
                featuredImage {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function HomeProductsSection() {
  const { loading, error, data } = useQueryWithLanguage(GET_PRODUCTS_HOME);

  const productSection = data?.pages?.nodes[0]?.[homeProdKey]?.productSection;

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Something went wrong</p>;
  }

  return (
    <div className="HomeProductsSection">
      <Container>
        <span className="lineBg"></span>
      </Container>
      <div className="HomeProductsSection--banner">
        <div className="HomeProductsSection--banner-dsc text-center">
          <h1
            dangerouslySetInnerHTML={{
              __html: productSection?.productSectionTitle,
            }}
          ></h1>
          <p
            dangerouslySetInnerHTML={{
              __html: productSection?.productSectionDescription,
            }}
          ></p>
          <Link className="prodLink" to={'/products'}>
            <span></span>
            {productSection?.productSectionLink}
          </Link>
        </div>
      </div>
      <HomeProductSlider products={productSection?.productsHome} />
      <HomeFaqs />
    </div>
  );
}
