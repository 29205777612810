import React from 'react';
import { Container } from 'reactstrap';
import { gql } from 'apollo-boost';
import BlueContainer from '../SharedComponents/BlueContainer/BlueContainer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductCategoriesLoader from './ProductCategoriesLoader';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';

import './ProductCategories.scss';

interface Post {
  id: string;
  slug: string;
  name: string;
  ProductCategoriesFields?: {
    productCategories?: {
      image?: {
        mediaItemUrl: string;
      };
    };
  };
  ProductCategoriesFields_sq?: {
    productCategories?: {
      image?: {
        mediaItemUrl: string;
      };
    };
  };
}

const homeProductCategoriesFieldsKey = makeLanguageFieldKey(
  'ProductCategoriesFields'
);

const GET_CATEGORIES = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    productCategories(where: { language: $language }) {
      nodes {
        id
        slug
        name
        ${homeProductCategoriesFieldsKey} {
          productCategories {
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

function ProductCategories() {
  // const router = useRouteMatch();
  const { t } = useTranslation();

  const { loading, error, data } = useQueryWithLanguage(GET_CATEGORIES);

  if (loading) {
    return (
      <div className="ProductCategories">
        <BlueContainer>
          <Container>
            <ProductCategoriesLoader />
          </Container>
        </BlueContainer>
      </div>
    );
  }

  if (error) {
    return <div className="text-danger">Error while loading posts ...</div>;
  }

  const productCategories = data?.productCategories?.nodes || [];

  return (
    <div className="ProductCategories">
      <BlueContainer>
        <Container>
          <h1
            className="mainTitle"
            dangerouslySetInnerHTML={{
              __html: t('Product Categories'),
            }}
          ></h1>

          <div className="ProductCategories-Items">
            <div className="box-row">
              {productCategories.length === 0 ? (
                <div>There are no available posts !</div>
              ) : (
                productCategories.map((post: Post) => {
                  let img: string | undefined = '';

                  if (post.ProductCategoriesFields) {
                    img =
                      post?.ProductCategoriesFields?.productCategories?.image
                        ?.mediaItemUrl;
                  } else {
                    img =
                      post?.ProductCategoriesFields_sq?.productCategories?.image
                        ?.mediaItemUrl;
                  }
                  const imgUrl = img || '';

                  return (
                    <div className="box" key={post.id}>
                      <Link to={`/products/${post.slug}`}>
                        <div className="overlay"></div>
                        <img src={imgUrl} alt={post.name} />
                        <div className="box-dsc">
                          <p>{post.name}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </Container>
      </BlueContainer>
    </div>
  );
}

export default ProductCategories;
