import React from 'react';
import Carousel from 'react-multi-carousel';
import { Container } from 'reactstrap';
import ProductItem, { Product } from '../ProductItem/ProductItem';
import 'react-multi-carousel/lib/styles.css';
import './HomeProductSlider.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};

interface Props {
  products?: Product[];
}

export function HomeProductSlider(props: Props) {
  return (
    <div className="HomeProductSlider">
      <Container>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false}
          infinite={true}
          autoPlaySpeed={500}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {!props.products || props.products.length === 0 ? (
            <div>There are no products ...</div>
          ) : (
            props.products.map(product => (
              <ProductItem key={product.id} product={product} />
            ))
          )}
        </Carousel>
      </Container>
    </div>
  );
}
