import React, { useState } from 'react';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import cs from 'classnames';
import { getPathSlug } from '../../../assets/Helpers';
import { mainMenuKey } from '../../../hooks/useNavigationQuery';

import './HeaderNavigation.scss';

const isDesktop = window.innerWidth > 767;

const GET_MENU_QUERY = gql`
  query MyQuery {
    menus(where: { location: ${mainMenuKey} }) {
      nodes {
        id
        menuItems {
          nodes {
            label
            id
            url
          }
        }
      }
    }
  }
`;

const HeaderNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_MENU_QUERY);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (error) return null;

  const menuData = data?.menus?.nodes[0]?.menuItems?.nodes || null;

  return (
    <>
      {!loading && (
        <>
          <div className="HeaderNavigation">
            <div
              className={cs({
                hambuger: true,
                'hambuger--active': isOpen,
              })}
              onClick={toggle}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            {isDesktop && (
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  {menuData.map((menuItem: any) => {
                    return (
                      <NavItem key={menuItem.id}>
                        <NavLink
                          to={`/${getPathSlug(menuItem.url)}`}
                          activeClassName="active"
                        >
                          {menuItem.label}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Collapse>
            )}
          </div>
          {!isDesktop && (
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {menuData.map((menuItem: any) => {
                  return (
                    <NavItem key={menuItem.id}>
                      <NavLink
                        onClick={toggle}
                        to={`/${getPathSlug(menuItem.url)}`}
                        activeClassName="active"
                      >
                        {menuItem.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </Collapse>
          )}
        </>
      )}
    </>
  );
};

export default HeaderNavigation;
