import React from 'react';

import './ServicesItem.scss';

const ServicesItem = (props: any) => {
  const renderHTML = (rawHTML: string) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <div className="itemHolder">
      <div className="servicesLeftSide">
        <img className="icon" src={props.icon} alt="" />
        <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>
      </div>
      <div className="servicesRightSide">{renderHTML(props.content)}</div>
    </div>
  );
};

export default ServicesItem;
