import { ApolloLink, NextLink, Operation } from "@apollo/client";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-boost";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  headers: {
    "Content-Type": "application/json"
  }
}) as any;

const middleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  const session = localStorage.getItem("woo-session");

  if (!session) {
    return forward(operation);
  }

  operation.setContext(() => ({
    headers: {
      "woocommerce-session": `Session ${session}`
    }
  }));

  return forward(operation);
});

const afterware = new ApolloLink((operation: Operation, forward: NextLink) =>
  forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    const session = headers.get("woocommerce-session");
    const localStorageSession = localStorage.getItem("woo-session");

    if (!session) return response;

    if (session === "false") {
      localStorage.removeItem("woo-session");
      return response;
    }

    if (localStorageSession !== session) {
      localStorage.setItem("woo-session", session);
      return response;
    }

    return response;
  })
);

const link = middleware.concat(afterware.concat(httpLink)) as any;

export function createApolloClient() {
  return new ApolloClient({
    link: link,
    cache: new InMemoryCache()
  });
}
