import React from 'react';
import {Container} from 'reactstrap';

import './PageBanner.scss';

interface Props {
    title: any;
    icon?: string;
    children?: React.ReactNode;
}

const PageBanner = (props: Props) => {
    const {children, title} = props
    return (
        <div className={children ? "PageBanner PageBanner--extended" : "PageBanner"}>
            <Container>
                <div className="bannerContent">
                    <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
                    <span className="bannerIcon">
                        {props.icon ? <img src={props.icon} alt="page banner icon" /> : null}
                    </span>
                    {children}
                </div>
            </Container>
        </div>
    )
}

export default PageBanner;
