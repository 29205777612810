import React, { useState } from 'react';
import { Collapse, Container } from 'reactstrap';
import { gql } from 'apollo-boost';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import BlueContainer from '../SharedComponents/BlueContainer/BlueContainer';
import faqIcon from '../../images/faq-icon.png';
import { useTranslation } from 'react-i18next';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './Faq.scss';
import { Loader } from '../SharedComponents/Loader/Loader';

const faqKey = makeLanguageFieldKey('FaqArticles');
const GET_FAQ_CONTENT = gql`
  query FaqQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "faq", language: $language }) {
      nodes {
        ${faqKey} {
          items {
            content
            fieldGroupName
            title
          }
        }
      }
    }
  }
`;

export type FaqPost = {
  title: string;
  content: string;
};

export const Faq = () => {
  const [activePost, setActivePost] = useState<string | null>(null);
  const { t } = useTranslation();
  const { loading, error, data } = useQueryWithLanguage(GET_FAQ_CONTENT);

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: '100%' }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>Something went wrong</p>;
  }
  const faqContent = data?.pages?.nodes[0][faqKey]?.items;

  const toggle = (id: string | null) => {
    if (activePost === id) {
      setActivePost(null);
    } else {
      setActivePost(id);
    }
  };

  return (
    <div className="Faq">
      <PageBanner
        title={t('Frequently Asked <br><b>Questions</b>')}
        icon={faqIcon}
      />
      <BlueContainer>
        <Container>
          <div className="Faq-posts">
            {!faqContent || faqContent.length === 0 ? (
              <div>There are no posts !</div>
            ) : (
              faqContent.map((post: FaqPost, i: number) => {
                const id = `${post.title}-${i}`;
                return (
                  <div
                    className="Faq-post"
                    data-opened={activePost === id}
                    key={id}
                  >
                    <div className="Faq-post-title" onClick={() => toggle(id)}>
                      <span>{post.title}</span>
                    </div>
                    <Collapse
                      className="Faq-post-content-holder"
                      isOpen={activePost === id}
                    >
                      <div
                        className="Faq-post-content"
                        dangerouslySetInnerHTML={{
                          __html: post.content || '',
                        }}
                      />
                    </Collapse>
                  </div>
                );
              })
            )}
          </div>
        </Container>
      </BlueContainer>
    </div>
  );
};
