import React from 'react';
import { Container } from 'reactstrap';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import BlueContainer from '../SharedComponents/BlueContainer/BlueContainer';
import faqIcon from '../../images/blog-icon.png';
import BlogItem from '../SharedComponents/BlogItem/BlogItem';
import { gql } from 'apollo-boost';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './Blog.scss';
const blogKey = makeLanguageFieldKey('blogContent');
const GET_DESCRIPTION = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    categories(where: { nameLike: "blog", language: $language }) {
      nodes {
        ${blogKey} {
          categoryContent
        }
      }
    }
  }
`;

const GET_BLOG_POSTS = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    categories(where: { nameLike: "blog", language: $language }) {
      nodes {
        posts {
          nodes {
            content
            link
            id
            title
            slug
            featuredImage {
              mediaItemUrl
            }
            excerpt
            date
          }
        }
      }
    }
  }
`;

export default function Blog() {
  const { data } = useQueryWithLanguage(GET_BLOG_POSTS);

  const { data: dataContent } = useQueryWithLanguage(GET_DESCRIPTION);
  const contentData =
    dataContent?.categories.nodes[0]?.[blogKey]?.categoryContent || null;
  const partnersContent = data?.categories?.nodes[0]?.posts?.nodes || [];
  return (
    <div className="Faq">
      <PageBanner title={contentData} icon={faqIcon} />
      <BlueContainer>
        <Container>
          <div className="my-row">
            {partnersContent.map((post: any) => (
              <div
                key={post.title}
                className="Blog--items column-4 column-mob-12"
              >
                <BlogItem
                  id={post.id}
                  link={post.slug}
                  title={post.title}
                  date={post.date}
                  image={post.featuredImage.mediaItemUrl}
                  description={post.excerpt}
                />
              </div>
            ))}
          </div>
        </Container>
      </BlueContainer>
    </div>
  );
}
