import React, { useState } from 'react';
import { Alert, Input, FormGroup, FormFeedback } from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import './ContactForm.scss';

const INSERT_CONTACT_ENTRY = gql`
  mutation MyMutation(
    $email: String!
    $message: String!
    $nameSurname: String!
  ) {
    contact(
      input: {
        clientMutationId: "asdfasdfadsfasf"
        email: $email
        message: $message
        nameSurname: $nameSurname
      }
    ) {
      message
    }
  }
`;

interface Props {
  description?: string;
}
export default function ContactForm(props: Props) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({} as any);

  const [contact, { data, loading, error }] = useMutation(INSERT_CONTACT_ENTRY);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  if (data) {
    return <p className="success-msg">{data.contact.message}</p>;
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getErrorObject = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {}
    return null;
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await contact({
        variables: {
          email: formData.email,
          message: formData.message,
          nameSurname: formData.name,
        },
      });
    } catch (error) {
      if (error.graphQLErrors[0].message) {
        const parsed = getErrorObject(error.graphQLErrors[0].message) || {};
        setErrors(parsed);
      }
    }
  };

  return (
    <div className="ContactForm">
      <div className="ContactForm-dsc">
        <p>{props?.description}</p>
      </div>
      {error && (
        <Alert color="warning">Ju lutemi plotesoni te gjitha fushat</Alert>
      )}
      <form onSubmit={handleFormSubmit}>
        <div className="input-holder">
          <FormGroup>
            <Input
              id="name"
              name="name"
              type="text"
              invalid={!!errors['nameSurname'] || undefined}
              value={formData.name}
              onChange={handleInputChange}
              placeholder={t('Name and Surname*')}
            />
            <FormFeedback>{errors['nameSurname']}</FormFeedback>
          </FormGroup>
        </div>
        <div className="input-holder right-align">
          <FormGroup>
            <Input
              id="email"
              name="email"
              type="email"
              invalid={!!errors['email'] || undefined}
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email*"
            />
            <FormFeedback>{errors['email']}</FormFeedback>
          </FormGroup>
        </div>
        <div className="text-area-holder">
          <FormGroup>
            <Input
              id="message"
              type="textarea"
              name="message"
              invalid={!!errors['message'] || undefined}
              value={formData.message}
              onChange={handleInputChange}
              placeholder={t('Write your request to us')}
            />
            <FormFeedback>{errors['message']}</FormFeedback>
          </FormGroup>
        </div>
        <button
          disabled={loading}
          className={`submit-btn ${loading ? 'submit-btn--spinning' : ''}`}
          type="submit"
        >
          {t('Submit your request')}
        </button>
      </form>
    </div>
  );
}
