import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './BlogItem.scss';

interface Props {
  id?: string;
  link?: string;
  image?: string;
  date?: string;
  title?: string;
  description?: string;
}

function BlogItem(props: Props) {
  return (
    <div className="BlogItem">
      <Link to={`/blog/${props.link}`}>
        <div className="BlogItem--thumb">
          <img src={props.image} alt="" />
        </div>
        <div className="BlogItem--dsc">
          <span>{moment(props.date).format('MMM DD, YYYY')}</span>
          <h3>{props.title}</h3>
          {props.description && (
            <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default BlogItem;
