import React from 'react';
import GoogleMapReact from 'google-map-react';
import locationIcon from '../../../images/location-icon.png';

import './GoogleMap.scss';

interface MarkerProps {
  lat: number;
  lng: number;
}

const MarkerStyles = {
  Marker: {
    width: '300px',
    height: '300px',
    margin: '-50px 0px 0px 150px',
    // border: '1px solid red',
  },
  MarkerIcon: {
    width: '100%',
    height: '100%',
  },
};

const Marker = (props: MarkerProps) => (
  <div
    className="Marker"
    style={{ ...MarkerStyles.Marker }}
    onClick={() => {
      window.open(
        'https://www.google.com/maps?ll=42.612286,21.140251&z=17&t=m&hl=en-US&gl=US&mapclient=apiv3&cid=5605488007903124121',
        '_blank'
      );
    }}
  >
    <img
      style={{ ...MarkerStyles.MarkerIcon }}
      src={locationIcon}
      alt="Marker"
    />
  </div>
);

export const GoogleMap = () => {
  const defaultLat = 42.613333;
  const defaultLng = 21.140211;
  return (
    <div className="GoogleMap">
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCxxX74N5ItPCWtbsTu_YqmFNfgiTT_CPI' }}
          // defaultCenter={{ lat: 42.632866, lng: 21.151984 }}
          defaultCenter={{ lat: defaultLat, lng: defaultLng }}
          defaultZoom={18}
          options={{
            zoomControl: true,
            minZoom: 12,
            styles: [
              {
                featureType: 'all',
                elementType: 'all',
                stylers: [
                  {
                    invert_lightness: true,
                  },
                  {
                    saturation: 10,
                  },
                  {
                    lightness: 30,
                  },
                  {
                    gamma: 0.5,
                  },
                  {
                    hue: '#435158',
                  },
                ],
              },
            ],
          }}
        >
          <Marker lat={defaultLat} lng={defaultLng} />
        </GoogleMapReact>
      </div>
    </div>
  );
};
