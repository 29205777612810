import React, { useState } from 'react';
import { Container, Input, Button, Form } from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import { useNavigationQuery } from '../../hooks/useNavigationQuery';
import { getPathSlug } from '../../assets/Helpers';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

interface StateProps {
  name: string;
  email: string;
}

export default function Footer() {
  const navigationQuery = useNavigationQuery();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<StateProps>({
    name: '',
    email: '',
  });
  const [error, setError] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setError('');
    setMessage('');
    setLoading(true);
    fetch('https://management.inetkosova.com/wp-json/app/v1/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(function (res) {
        console.log('res', res);
        if (!res.ok) {
          setError("Something wen't wrong");
        } else {
          setMessage('Thank you! You have been subscribed.');
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('error', e.message);
        setError(e.message);
        setLoading(false);
      });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div className="Footer">
      <Container>
        <div className="Footer-inner">
          <NavLink to="" className="navbar-brand">
            <img src={require('../../images/logo.svg')} alt="" />
          </NavLink>
          <div className="footerNav">
            {!navigationQuery.data || navigationQuery.data.length === 0 ? (
              <div>No menu items</div>
            ) : (
              navigationQuery.data.map((menu) => (
                <NavLink key={menu.id} to={`/${getPathSlug(menu.url)}`}>
                  {menu.label}
                </NavLink>
              ))
            )}
          </div>
          <div className="socialMedia">
            <p>{t('Follow us')}:</p>
            <ul>
              <li className="fb">
                <Link target="_blank" to={'https:www.facebook.com/inetkosova/'}>
                  &nbsp;
                </Link>
              </li>
              <li className="insta">
                <Link
                  target="_blank"
                  to={'https://www.instagram.com/inetkosova/'}
                >
                  &nbsp;
                </Link>
              </li>
            </ul>
          </div>
          <div className="newsletter">
            <h2>Subscribe</h2>
            <Form>
              {error !== '' && <p>{error}</p>}
              {message !== '' && <p className="success-subscribe">{message}</p>}
              <Input
                type="text"
                name="name"
                placeholder={t('Name Surname')}
                value={formData.name}
                onChange={handleInputChange}
              ></Input>
              <Input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              ></Input>
              <Button onClick={handleSubmit} disabled={loading}>
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 38 38"
                    stroke="#fff"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g transform="translate(1 1)" stroke-width="2">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                        <path
                          d="M36 18c0-9.94-8.06-18-18-18"
                          transform="rotate(29.9261 18 18)"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </g>
                    </g>
                  </svg>
                ) : (
                  t('Send')
                )}
              </Button>
            </Form>
          </div>
        </div>

        <div className="terms-privacy-area">
          <Link to={'/terms-conditions'}> {t('Terms & Conditions')}</Link>
          <Link to={'/privacy-policy'}> {t('Privacy Policy')}</Link>
        </div>
        <p className="copyRights">
          © {new Date().getFullYear()} Inet Kosova .{t('All Rights Reserved')}.
        </p>
      </Container>
    </div>
  );
}
