import React from 'react';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import faqIcon from '../../images/blog-icon.png';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { gql } from 'apollo-boost';

import './TermsCondition.scss';
const GET_TERMSCONDITION_CONTENT = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "terms-conditions", language: $language }) {
      nodes {
        content
      }
    }
  }
`;

export default function TermsContition() {
  const { t } = useTranslation();
  const { data } = useQueryWithLanguage(GET_TERMSCONDITION_CONTENT);
  const termsContent = data?.pages?.nodes[0]?.content || null;

  // console.log('GET_TERMSCONDITION_CONTENT', data);

  return (
    <div className="TermsContition">
      <PageBanner icon={faqIcon} title={t('Terms & Conditions')} />
      <Container>
        <div
          className="TermsContition--content"
          dangerouslySetInnerHTML={{ __html: termsContent }}
        >
          {}
        </div>
      </Container>
    </div>
  );
}
