import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import { createApolloClient } from './createApolloClient';
import LanguageProvider from './LanguageProvider';
import App from './components/App/App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';

const apolloClient = createApolloClient();

console.log(apolloClient);

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter basename="/">
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
