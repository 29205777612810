import { gql } from 'apollo-boost';
import { useQueryWithLanguage } from '../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../lib/getActiveLanguage';
const partnersKey = makeLanguageFieldKey('partnerItems');
const GET_PARTNERS_ITEM = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "partners", language: $language }) {
      nodes {
        id
        	${partnersKey} {
          items {
            content
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;
export type PartnerItem = {
  content?: string;
  image?: {
    mediaItemUrl: string;
  };
};

export const usePartnersQuery = () => {
  const { loading, error, data, refetch } = useQueryWithLanguage(
    GET_PARTNERS_ITEM
  );

  const partnersData: PartnerItem[] | null =
    data?.pages?.nodes[0][partnersKey]?.items || null;

  // console.log('usePartnersQuery partnersData', partnersData);

  return {
    loading,
    error,
    data: partnersData,
    refetch,
  };
};
