import { gql } from 'apollo-boost';
import { useQueryWithLanguage } from '../hooks/useQueryWithLanguage';

const GET_BLOG_POSTS = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    categories(where: { nameLike: "blog", language: $language }) {
      nodes {
        posts {
          nodes {
            content
            link
            id
            title
            slug
            featuredImage {
              mediaItemUrl
            }
            excerpt
            date
          }
        }
      }
    }
  }
`;

export const useGetBlogsQuery = () => {
  const { loading, error, data, refetch } = useQueryWithLanguage(
    GET_BLOG_POSTS
  );

  const posts = data?.categories?.nodes[0]?.posts?.nodes || [];

  return {
    loading,
    error,
    data: posts,
    refetch,
  };
};
