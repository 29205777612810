import React from 'react';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import faqIcon from '../../images/blog-icon.png';
import { Container } from 'reactstrap';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';

import './PrivacyPolicy.scss';
const GET_PRIVACYPOLICY_CONTENT = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "privacy-policy", language: $language }) {
      nodes {
        content
      }
    }
  }
`;

export default function PrivacyPolicy() {
  const { data } = useQueryWithLanguage(GET_PRIVACYPOLICY_CONTENT);
  const { t } = useTranslation();
  const PrivacyPolicy = data?.pages?.nodes[0]?.content || null;

  return (
    <div className="TermsContition">
      <PageBanner icon={faqIcon} title={t('Privacy Policy')} />
      <Container>
        <div
          className="TermsContition--content"
          dangerouslySetInnerHTML={{ __html: PrivacyPolicy }}
        ></div>
      </Container>
    </div>
  );
}
