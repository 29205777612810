import React from 'react';
import { gql } from 'apollo-boost';
import { Container } from 'reactstrap';
import BlueContainer from '../SharedComponents/BlueContainer/BlueContainer';
import ContactForm from '../SharedComponents/ContactForm/ContactForm';
import { GoogleMap } from '../SharedComponents/GoogleMap/GoogleMap';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './Contact.scss';
import { Loader } from '../SharedComponents/Loader/Loader';
const contactKey = makeLanguageFieldKey('ContactFields');
const GET_CONTACT_CONTENT = gql`
  query ContactQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "contact", language: $language }) {
      nodes {
        ${contactKey} {
          contactFields {
            email
            location
            tel
					}
					contactDescription
        }
        content
      }
    }
  }
`;

export default function Contact() {
  const { data, error, loading } = useQueryWithLanguage(GET_CONTACT_CONTENT);
  const contactTitle = data?.pages?.nodes[0]?.content;
  const contactContent = data?.pages?.nodes[0][contactKey]?.contactFields || [];
  const contactDescription =
    data?.pages?.nodes[0][contactKey]?.contactDescription || [];

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center mt-5"
        style={{ height: '100%' }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>Something went wrong</p>;
  }

  return (
    <div className="Contact">
      <BlueContainer>
        <div className="Contact-wrapp">
          <Container>
            <div className="Contact-wrapp-leftSide">
              <h1 dangerouslySetInnerHTML={{ __html: contactTitle }}></h1>
              <ul>
                <li className="tel-area">
                  <span></span>
                  <p>{contactContent.tel}</p>
                </li>
                <li className="email-area">
                  <span></span>
                  <p>{contactContent.email} </p>
                </li>
                <li className="location-area">
                  <span></span>
                  <p>{contactContent.location}</p>
                </li>
              </ul>
            </div>
            <ContactForm description={contactDescription} />
          </Container>
        </div>
        <div>
          <GoogleMap />
        </div>
      </BlueContainer>
    </div>
  );
}
