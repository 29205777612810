import React from 'react';
import { Container } from 'reactstrap';
import './Clients.scss';

type ClientImage = {
  icon: {
    mediaItemUrl: string;
  };
};

interface Props {
  title: string;
  description?: string;
  clients: ClientImage[];
  limit?: number;
}

export const Clients = (props: Props) => {
  const { title, description, clients } = props;

  if (!title || !description || !clients) {
    return <></>;
  }

  return (
    <div className="Clients">
      <Container>
        <span className="lineBg"></span>
        <div className="Clients--dsc text-center">
          <h2>{props.title}</h2>
          {props.description && (
            <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
          )}
        </div>
        <div className="Clients--items">
          {props.clients.map((client, index) => {
            if (props.limit && index >= props.limit) {
              return null;
            }
            return (
              <div
                className="Clients--items--item"
                key={client.icon.mediaItemUrl}
              >
                <div className="Clients--items--item--img">
                  <img src={client.icon.mediaItemUrl} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
