import React, { ReactNode } from 'react';

import './BlueContainer.scss';

interface Props {
    children: ReactNode;
}


const BlueContainer = (props: Props) => {
    return (
        <div className="BlueContainer">
            {props.children}
        </div>
    )
}

export default BlueContainer;
