import React from 'react';
import { Container } from 'reactstrap';
import { ProductSlider } from '../SharedComponents/ProductSlider/ProductSlider';
import { ProductSingleAccordion } from '../SharedComponents/ProductSingleAccordion/ProductSingleAccordion';
import { SimilarProductPost } from '../SharedComponents/SimilarProductPost/SimilarProductPost';
import { RouteComponentProps } from 'react-router';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import { gql } from 'apollo-boost';

import './ProductSingle.scss';
import { Loader } from '../SharedComponents/Loader/Loader';

const productSingleKey = makeLanguageFieldKey('ProductCategoriesSingle');

const GET_CATEGORY_SINGLE_POST = gql`
  query getCategorySinglePost($slug: [String], $name: String, $language: LanguageCodeFilterEnum) {
    productCategories(where: { slug: $slug, language: $language }) {
      nodes {
        products(where: { name: $name }) {
          nodes {
            title
            id
            ${productSingleKey} {
              sliderImages {
                image {
                  mediaItemUrl
                }
              }
              accordionImageSpecs {
                mediaItemUrl
              }
              productContent {
                accordionContent
                accordionTitle
              }
            }
          }
        }
      }
    }
  }
`;

export function ProductSingle(
  props: RouteComponentProps<{
    productCategorySlug: string;
    productSlug: string;
  }>
) {
  const productCategorySlug = props.match.params.productCategorySlug;
  const productSlug = props.match.params.productSlug;

  const { loading, error, data } = useQueryWithLanguage(
    GET_CATEGORY_SINGLE_POST,
    {
      variables: {
        slug: productCategorySlug,
        name: productSlug,
      },
    }
  );
  const sliderImages =
    data?.productCategories?.nodes[0]?.products?.nodes[0]?.[productSingleKey]
      ?.sliderImages;

  const title = data?.productCategories?.nodes[0]?.products?.nodes[0]?.title;

  const accordionImg =
    data?.productCategories?.nodes[0]?.products?.nodes[0]?.[productSingleKey]
      ?.accordionImageSpecs?.mediaItemUrl;

  const accordionPosts =
    data?.productCategories?.nodes[0]?.products?.nodes[0]?.[productSingleKey]
      ?.productContent;

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center mt-5"
        style={{ height: '100%' }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="ProductSingle">
      <Container>
        <div className="ProductSingle-wrap">
          <ProductSlider sliderImages={sliderImages} />
          <ProductSingleAccordion
            title={title}
            accordionImg={accordionImg}
            accordionPosts={accordionPosts}
          />
        </div>
      </Container>
      <SimilarProductPost slug={productCategorySlug} />
    </div>
  );
}
