import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
} from 'reactstrap';
import { gql } from 'apollo-boost';
import { useQueryWithLanguage } from '../../../hooks/useQueryWithLanguage';
import './Slider.scss';
import { removeHTMLTags } from '../../../lib/removeHTMLTags';

const GET_BLOG_POSTS = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    categories(where: { nameLike: "blog", language: $language }, last: 4) {
      nodes {
        posts {
          nodes {
            content
            link
            title
            featuredImage {
              mediaItemUrl
            }
            excerpt
          }
        }
      }
    }
  }
`;

export default function Slider() {
  const { data } = useQueryWithLanguage(GET_BLOG_POSTS);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const partnersContent = data?.categories?.nodes[0]?.posts?.nodes || [];

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === partnersContent.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? partnersContent.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = partnersContent.map((items: any) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={items.link}
      >
        <img src={items.featuredImage.mediaItemUrl} alt={''} />
        <CarouselCaption
          className="fade-in-up"
          captionText={removeHTMLTags(items.excerpt)}
          captionHeader={items.title}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <Container>
        <CarouselIndicators
          items={partnersContent}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
      </Container>
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}
