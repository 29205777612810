import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ContainerSmall = (props: Props) => {
  return (
    <div className={`ContainerSmall ${props.className || ''}`}>
      {props.children}
    </div>
  );
};
