import React from 'react';
import { Container } from 'reactstrap';
import { usePartnersQuery } from '../../hooks/usePartnersQuery';
import { useTranslation } from 'react-i18next';

import './HomePartners.scss';

export default function HomePartners() {
  const partnersQuery = usePartnersQuery();
  const { t } = useTranslation();

  return (
    <div className="HomePartners">
      <Container>
        <div className="HomePartners--dsc">
          <h1>{t('Our Partners')}</h1>
          {/* <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form
          </p> */}
        </div>

        {!partnersQuery.data || partnersQuery.data.length === 0 ? (
          <div>There are no partners data!</div>
        ) : (
          partnersQuery.data.map((post, i) => {
            const imageUrl = post?.image?.mediaItemUrl;
            return (
              <div key={`${imageUrl}-${i}`} className="HomePartners--items">
                {imageUrl && <img src={imageUrl} alt="" />}
              </div>
            );
          })
        )}
      </Container>
    </div>
  );
}
