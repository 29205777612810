import React from 'react';
import Slider from '../SharedComponents/Slider/Slider';
import BlueContainer from '../SharedComponents/BlueContainer/BlueContainer';
import { gql } from 'apollo-boost';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimilarBlogPost from '../SharedComponents/SimilarBlogPost/SimilarBlogPost';
import Contact from '../Contact/Contact';
import { Clients } from '../SharedComponents/ClientsItem/Clients';
import { useClientsQuery } from '../../hooks/useClientsQuery';
import { HomeProductsSection } from '../SharedComponents/HomeProductsSection/HomeProductsSection';
import HomePartners from '../HomePartners/HomePartners';
import { useTranslation } from 'react-i18next';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';
import './Home.scss';
import { Loader } from '../SharedComponents/Loader/Loader';

const servicesKey = makeLanguageFieldKey('Services');
const GET_SERVICES_CONTENT = gql`
query ServicesQuery($language: LanguageCodeFilterEnum) {
	pages(where: { nameIn: "services", language: $language }) {
		nodes {
			content
			${servicesKey} {
				pageBannerTitle
				serviceItem {
					content
					title
					icon {
						mediaItemUrl
					}
				}
			}
		}
	}
}
`;

const homeKey = makeLanguageFieldKey('Home');
const GET_ABOUT_CONTENT = gql`
  query AboutQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "home", language: $language }) {
      nodes {
        ${homeKey} {
          about {
            fieldGroupName
            sectionContent
            sectionTitle
            image {
              mediaItemUrl
            }
          }
          blogTitleSection
        }
      }
    }
  }
`;

export default function Home() {
  const { t } = useTranslation();

  const servicesQuery = useQueryWithLanguage(GET_SERVICES_CONTENT);
  const aboutQuery = useQueryWithLanguage(GET_ABOUT_CONTENT);
  const clientsData = useClientsQuery();

  const isLoading =
    servicesQuery.loading || aboutQuery.loading || clientsData.loading;

  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center mt-5"
        style={{ height: '100%' }}
      >
        <Loader />
      </div>
    );
  }

  if (aboutQuery.error || clientsData.error) {
    return <p>Something went wrong</p>;
  }

  const pageServicesTitle =
    servicesQuery?.data?.pages.nodes[0][servicesKey]?.pageBannerTitle || null;

  const pageServicesContent =
    servicesQuery?.data?.pages.nodes[0]?.content || null;

  const dataAboutContent =
    aboutQuery?.data?.pages?.nodes[0]?.[homeKey]?.about || null;

  return (
    <div className="Home">
      <div className="HomeSlider">
        <Slider />
      </div>
      <BlueContainer>
        <div className="ServiceSection fade-in-up">
          {servicesQuery.error ? (
            <div>Something went wrong with servies</div>
          ) : (
            <div className="Services">
              <Container>
                <div className="Services--content">
                  <div className="column-content">
                    <h2
                      dangerouslySetInnerHTML={{ __html: pageServicesTitle }}
                    ></h2>
                  </div>
                  <div className="column-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageServicesContent,
                      }}
                    ></p>
                  </div>
                </div>
                <span className="lineBg"></span>
                <div className="Service-wrap text-center">
                  {servicesQuery?.data.pages.nodes[0][
                    servicesKey
                  ]?.serviceItem.map((post: any) => (
                    <div
                      key={post.icon.mediaItemUrl}
                      className="Service-wrap--item"
                    >
                      <img src={post.icon.mediaItemUrl} alt="" />
                      <p dangerouslySetInnerHTML={{ __html: post.title }}></p>
                    </div>
                  ))}
                </div>
              </Container>
            </div>
          )}
        </div>

        <div className="AboutSectionHome">
          <Container>
            <div className="contentLeft">
              <h1
                dangerouslySetInnerHTML={{
                  __html: dataAboutContent?.sectionTitle,
                }}
              ></h1>
              <article
                dangerouslySetInnerHTML={{
                  __html: dataAboutContent?.sectionContent,
                }}
              ></article>
              <div className="link-holder">
                <Link to={'/about'}>
                  <span></span>
                  {t('Read more about us')}
                </Link>
              </div>
            </div>
            <div className="contentRight">
              <div className="contentRight--img">
                <img src={dataAboutContent?.image?.mediaItemUrl} alt="" />
              </div>
            </div>
          </Container>
        </div>
      </BlueContainer>
      <Clients
        title={clientsData?.data?.clientTitle}
        description={clientsData?.data?.clientDsc}
        clients={clientsData?.data?.items}
        limit={8}
      />
      <HomeProductsSection />
      <HomePartners />

      <SimilarBlogPost
        title={
          aboutQuery?.data?.pages?.nodes[0]?.[homeKey]?.blogTitleSection || ''
        }
        limit={3}
      />
      <Contact />
    </div>
  );
}
