import React from 'react';
import { Container } from 'reactstrap';
import PageBanner from '../SharedComponents/PageBanner/PageBanner';
import faqIcon from '../../images/about-icon.png';
import { gql } from 'apollo-boost';
import { Clients } from '../SharedComponents/ClientsItem/Clients';
import SimilarBlogPost from '../SharedComponents/SimilarBlogPost/SimilarBlogPost';
import { useClientsQuery } from '../../hooks/useClientsQuery';
import { useQueryWithLanguage } from '../../hooks/useQueryWithLanguage';
import { makeLanguageFieldKey } from '../../lib/getActiveLanguage';

import './About.scss';

const aboutKey = makeLanguageFieldKey('aboutPage');

const GET_DESCRIPTION_HISTORY = gql`
  query MyQuery($language: LanguageCodeFilterEnum) {
    pages(where: { nameIn: "about", language: $language }) {
      nodes {
        content
         ${aboutKey}  {
          historyContent
          historyTitle
          companyImage {
            mediaItemUrl
          }
        }
        id
        content
      }
    }
  }
`;

export default function About() {
  const { data } = useQueryWithLanguage(GET_DESCRIPTION_HISTORY);
  const clientsData = useClientsQuery();

  const bannerTitle = data?.pages?.nodes[0].content || null;
  const pageContent = data?.pages?.nodes[0][aboutKey] || null;
  const pageContentImage =
    data?.pages?.nodes[0][aboutKey]?.companyImage?.mediaItemUrl || null;
  const { clientTitle, clientDsc, items } = clientsData.data;

  return (
    <div className="About">
      <PageBanner title={bannerTitle} icon={faqIcon} />
      <div className="About_Content fade-in-up">
        <Container>
          <div className="contentLeft">
            <div className="contentLeft--img">
              <img src={pageContentImage} alt={pageContentImage} />
            </div>
          </div>
          <div className="contentRight">
            <h1>{pageContent?.historyTitle}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: pageContent?.historyContent }}
            ></div>
          </div>
        </Container>
      </div>
      {clientsData.loading ? (
        <div className="text-center">Loading clients..</div>
      ) : (
        <Clients title={clientTitle} description={clientDsc} clients={items} />
      )}
      <SimilarBlogPost title={'Case Studies'} />
    </div>
  );
}
